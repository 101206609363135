import {
    ON_CHECKED_ALL,
    ON_CHANGE_DATA_STORE,
    ON_SET_DETAIL_STORE,
    ON_SET_LIST_STORE,
    TOGGLE_DROPDOWN_SETTING,
    TOGGLE_ITEM_STATUS_STORE,
    TOGGLE_SELECTED_ITEM,
    RESET_STORE_FORM
} from '../actions/storeAction'


const initialState = {
    storeList: [],
    checkAll: false,
    dataEdit: {
        name: '',
        address: '',
        city: '',
        province: '',
        sub_district: '',
        postal_code: '',
        status: false
    }
}
export default (state = initialState, action) => {
    const storeList = state.storeList
    switch (action.type) {
        case ON_CHECKED_ALL:
            for (let i = 0; i < storeList.length; i++) {
                storeList[i].checked = action.check
            }
            state.checkAll = action.check
            return {
                ...state
            }
        case ON_CHANGE_DATA_STORE:
            state.dataEdit = {
                ...state.dataEdit,
                [action.key]: action.value
            }
            return {
                ...state
            }
        case ON_SET_DETAIL_STORE:
            state.dataEdit = action.data
            return {
                ...state
            }
        case TOGGLE_DROPDOWN_SETTING:
            for (let i = 0; i < storeList.length; i++) {
                storeList[i].dropdown = false
            }
            storeList[action.index].dropdown = action.checked
            return {
                ...state
            }
        case TOGGLE_ITEM_STATUS_STORE:
            state.storeList[action.index].status = action.checked
            return {
                ...state
            }
        case TOGGLE_SELECTED_ITEM:
            state.storeList[action.index].checked = action.checked
            if (!action.check) {
                state.checkAll = false
            }
            return {
                ...state
            }
        case ON_SET_LIST_STORE:
            state.storeList = action.data
            return {
                ...state
            }
        case RESET_STORE_FORM:
            state.dataEdit = {
                name: '',
                address: '',
                city: '',
                province: '',
                sub_district: '',
                postal_code: '',
                status: false
            }
            return {
                ...state
            }
        default:
            return state
    }
}