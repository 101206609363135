import { combineReducers } from 'redux'
import exampleReducer from './reducers/exampleReducer'
import adminReducer from './reducers/adminReducer'
import layoutReducer from './reducers/layoutReducer'
import categoryReducer from './reducers/categoryReducer'
import brandReducer from './reducers/brandReducer'
import productReducer from './reducers/productReducer'
import storeReducer from './reducers/storeReducer'
import bannerReducer from './reducers/bannerReducer'
import transactionReducer from './reducers/transactionReducer'
import newCustomerReducer from './reducers/newCustomerReducer'
import bundlingReducer from './reducers/bundlingReducer'
import uploadResepReducer from './reducers/uploadResepReducer'
import footerMenuReducer from './reducers/footerMenuReducer'

export default combineReducers({
    example: exampleReducer,
    admin: adminReducer,
    layout: layoutReducer,
    category: categoryReducer,
    brand: brandReducer,
    product: productReducer,
    store: storeReducer,
    banner: bannerReducer,
    transaction: transactionReducer,
    newCustomer: newCustomerReducer,
    uploadResep: uploadResepReducer,
    bundling: bundlingReducer,
    footerMenu: footerMenuReducer
});
