import {
    ON_CHECKED_ALL_TRANSACTION_UPLOAD_RESEP,
    TOGGLE_SELECTED_TRANSACTION_ITEM_UPLOAD_RESEP,
    ON_CHECKED_ALL_ITEMS_UPLOAD_RESEP,
    TOGGLE_SELECTED_ITEMS_UPLOAD_RESEP,
    SET_TRANSACTION_LIST_UPLOAD_RESEP,
    SET_DETAIL_TRANSACTION_UPLOAD_RESEP,
    SET_FILTER_DATE_START,
    SET_FILTER_DATE_END,
    SET_DETAIL_APOTEK_UPLOAD_RESEP,
    SET_SELECTED_APOTEK_UPLOAD_RESEP,
    SET_DETAIL_UPLOAD_RESEP,
    SET_DETAIL_ORDER_UPLOAD_RESEP,
    SET_DETAIL_SHIPPING_UPLOAD_RESEP,
    SET_DETAIL_PATIENT_UPLOAD_RESEP,
    SET_DETAIL_DOCTOR_UPLOAD_RESEP,
    SET_DETAIL_RECEIVER_UPLOAD_RESEP,
    SET_APOTEK_INFORMATION_UPLOAD_RESEP,
    SET_DOCTOR_INFORMATION_UPLOAD_RESEP,
    SET_ITEMS_UPLOAD_RESEP,
    SET_DIAGNOSA_UPLOAD_RESEP,
    DELETE_ITEMS_UPLOAD_RESEP,
    RESET_DETAIL_UPLOAD_RESEP

} from '../actions/uploadResepAction'


const initialState = {
    checkAll: false,
    checkAllItems: false,
    filter: {
        date_start: new Date(),
        date_end: new Date()
    },
    transactionList: [],
    ur_detail: {
        uploadResep: {
            orderCode: '',
            orderId: '',
            orderStatus: '',
            created_at: ''
        },
        order: {
            orderId: '',
            orderCode: '',
            orderStatus: '',
        },
        shipping: {
            deliveryId: '',
            driver: '',
            phone: '',
            ongkir: ''
        },
        patient: {
            name: '',
            email: '',
            phone: '',
            address: ''
        },
        doctor: {
            id: '',
            name: '',
            sip_no: '',
            str_no: ''
        },
        receiver: {
            name: '',
            phone: '',
            address: '',
            notes: '',
            lat: '',
            lng: ''
        },
        apotek: [],
        receipt: [],
        images: [],
        history: [],
        diagnosa: ''
    },
    selected_store: [],
    ur_isDetail: true,
    ur_isApotek: false,
    ur_isDokter: false
}

export default (state = initialState, action) => {
    const transactionList = state.transactionList
    const items = state.ur_detail.receipt
    switch (action.type) {
        case ON_CHECKED_ALL_TRANSACTION_UPLOAD_RESEP:
            for (let i = 0; i < transactionList.length; i++) {
                transactionList[i].checked = action.check
            }
            state.checkAll = action.check
            return {
                ...state
            }
        case TOGGLE_SELECTED_TRANSACTION_ITEM_UPLOAD_RESEP:
            state.transactionList[action.index].checked = action.check
            if (!action.check) {
                state.checkAll = false
            }
            return {
                ...state
            }
        case ON_CHECKED_ALL_ITEMS_UPLOAD_RESEP:
            for (let i = 0; i < items.length; i++) {
                items[i].checked = action.check
            }
            state.checkAllItems = action.check
            return {
                ...state
            }
        case TOGGLE_SELECTED_ITEMS_UPLOAD_RESEP:
            state.ur_detail.receipt[action.index].checked = action.check
            if (!action.check) {
                state.checkAllItems = false
            }
            return {
                ...state
            }
        case SET_TRANSACTION_LIST_UPLOAD_RESEP:
            state.transactionList = action.data
            return {
                ...state
            }
        case SET_DETAIL_TRANSACTION_UPLOAD_RESEP:
            state.ur_detail = action.data
            state.ur_isDetail = true
            return {
                ...state
            }
        case SET_APOTEK_INFORMATION_UPLOAD_RESEP:
            state.ur_isApotek = action.value
            return {
                ...state
            }
        case SET_DOCTOR_INFORMATION_UPLOAD_RESEP:
            state.ur_isDokter = action.value
            return {
                ...state
            }
        case SET_FILTER_DATE_START:
            state.filter.date_start = action.value
            return {
                ...state
            }
        case SET_FILTER_DATE_END:
            state.filter.date_end = action.value
            return {
                ...state
            }
        case SET_DETAIL_UPLOAD_RESEP:
            state.ur_detail.uploadResep = action.data
            return {
                ...state
            }
        case SET_DETAIL_ORDER_UPLOAD_RESEP:
            state.ur_detail.order = action.data
            return {
                ...state
            }
        case SET_DETAIL_SHIPPING_UPLOAD_RESEP:
            state.ur_detail.shipping = action.data
            return {
                ...state
            }
        case SET_DETAIL_PATIENT_UPLOAD_RESEP:
            state.ur_detail.patient = action.data
            return {
                ...state
            }
        case SET_DETAIL_DOCTOR_UPLOAD_RESEP:
            state.ur_detail.doctor = action.data
            return {
                ...state
            }
        case SET_DETAIL_APOTEK_UPLOAD_RESEP:
            if(state.ur_detail.apotek.length < 2) state.ur_detail.apotek = [...state.ur_detail.apotek, action.data]
            if(state.selected_store.length > 0) {
                const arr = []
                const store = state.selected_store
                state.ur_detail.apotek.map((apotek, index) => {
                    if(index === store[0].index){
                        apotek = action.data
                        return arr.push(apotek)
                    }else{
                        return arr.push(apotek)
                    }
                })
                state.ur_detail.apotek = arr
            }
            return {
                ...state
            }
        case SET_SELECTED_APOTEK_UPLOAD_RESEP:
            state.selected_store = action.data
            return {
                ...state
            }
        case SET_DETAIL_RECEIVER_UPLOAD_RESEP:
            state.ur_detail.receiver = action.data
            return {
                ...state
            }
        case SET_ITEMS_UPLOAD_RESEP: 
            state.ur_detail.receipt = [...state.ur_detail.receipt, action.data]
            return {
                ...state
            }
        case SET_DIAGNOSA_UPLOAD_RESEP: 
            state.ur_detail.diagnosa = action.value
            return {
                ...state
            }
        case DELETE_ITEMS_UPLOAD_RESEP:
            state.ur_detail.receipt = state.ur_detail.receipt.filter(item => item.checked !== action.value)
            return {
                ...state
            }
        case RESET_DETAIL_UPLOAD_RESEP:
            state.ur_detail = {
                uploadResep: {
                    orderCode: '',
                    orderId: '',
                    orderStatus: '',
                    created_at: ''
                },
                order: {
                    orderId: '',
                    orderCode: '',
                    orderStatus: '',
                },
                shipping: {
                    deliveryId: '',
                    driver: '',
                    phone: '',
                    ongkir: ''
                },
                patient: {
                    name: '',
                    email: '',
                    phone: '',
                    address: ''
                },
                doctor: {
                    id: '',
                    name: '',
                    sip_no: '',
                    str_no: ''
                },
                receiver: {
                    name: '',
                    phone: '',
                    address: '',
                    notes: '',
                    lat: '',
                    lng: ''
                },
                apotek: [],
                receipt: [],
                images: [],
                history: []
            }
            state.selected_store = []
            return {
                ...state
            }
        default:
            return state
    }
}