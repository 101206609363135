import {
    ON_CHECKED_ALL,
    ON_DELETE_IMAGE,
    ON_UPLOADED_IMAGE,
    TOGGLE_DROPDOWN_SETTING,
    TOGGLE_ITEM_STATUS_PRODUCT,
    TOGGLE_SELECTED_ITEM,
    TOGGLE_IMAGE_PRODUCT,
    ON_SET_DETAIL_PRODUCT,
    ON_CHANGE_DETAIL_PRODUCT,
    ON_SET_LIST_IMAGE,
    RESET_FORM
} from '../actions/productAction'


const initialState = {
    productList: [
        { id: 1, image: '', brand: 'Prenagen', category: 'Kehamilan', price: '30000', specialPrice: '18000', name: 'Prenagen Esensi', desc: '', status: true, checked: false, dropdown: false },
        { id: 2, image: '', brand: 'Prenagen', category: 'Menyusui', price: '30000', specialPrice: '18000', name: 'Prenagen Esensi', desc: '', status: false, checked: true, dropdown: false },
        { id: 3, image: '', brand: 'Prenagen', category: 'Menyusui', price: '30000', specialPrice: '18000', name: 'Prenagen Esensi', desc: '', status: true, checked: false, dropdown: false },
        { id: 4, image: '', brand: 'Prenagen', category: 'Menyusui', price: '30000', specialPrice: '18000', name: 'Prenagen Esensi', desc: '', status: true, checked: false, dropdown: false },
        { id: 5, image: '', brand: 'Prenagen', category: 'Menyusui', price: '30000', specialPrice: '18000', name: 'Prenagen Esensi', desc: '', status: true, checked: true, dropdown: false },
    ],
    checkAll: false,
    dataEdit: {
        id: 1,
        image: '',
        brand: 'Prenagen',
        category: '',
        price: '',
        specialPrice: '',
        name: '',
        desc: '',
        status: true
    },
    imageList: [],
}
export default (state = initialState, action) => {
    const productList = state.productList
    switch (action.type) {
        case ON_CHECKED_ALL:
            for (let i = 0; i < productList.length; i++) {
                productList[i].checked = action.check
            }
            state.checkAll = action.check
            return {
                ...state
            }
        case ON_DELETE_IMAGE:
            for (let i = 0; i < state.imageList.length; i++) {
                if (state.imageList[i].checked) {
                    state.imageList.splice(i, 1)
                }
            }
            return {
                ...state
            }
        case ON_UPLOADED_IMAGE:
            state.imageList.push({ id: state.imageList.length + 1, image: action.data, checked: false })
            return {
                ...state
            }
        case TOGGLE_DROPDOWN_SETTING:
            for (let i = 0; i < productList.length; i++) {
                productList[i].dropdown = false
            }
            productList[action.index].dropdown = action.checked
            return {
                ...state
            }
        case TOGGLE_ITEM_STATUS_PRODUCT:
            state.productList[action.index].is_display = action.checked
            return {
                ...state
            }
        case TOGGLE_SELECTED_ITEM:
            state.productList[action.index].checked = action.check
            if (!action.check) {
                state.checkAll = false
            }
            return {
                ...state
            }
        case TOGGLE_IMAGE_PRODUCT:
            state.imageList[action.index].checked = action.check
            return {
                ...state
            }
        case ON_SET_DETAIL_PRODUCT:
            state.dataEdit = action.data
            return {
                ...state
            }
        case RESET_FORM:
            return {
                ...state
            }
        case ON_CHANGE_DETAIL_PRODUCT:
            state.dataEdit = {
                ...state.dataEdit,
                [action.key]: action.value
            }
            return {
                ...state
            }
        case ON_SET_LIST_IMAGE:
            state.imageList = action.data
            return {
                ...state
            }
        default:
            return state
    }
}