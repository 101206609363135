export const ON_CHECKED_ALL = 'ON_CHECKED_ALL'
export const ON_DELETE_IMAGE = 'ON_DELETE_IMAGE'
export const ON_UPLOADED_IMAGE = 'ON_UPLOADED_IMAGE'
export const TOGGLE_DROPDOWN_SETTING = 'TOGGLE_DROPDOWN_SETTING'
export const TOGGLE_ITEM_STATUS_PRODUCT = 'TOGGLE_ITEM_STATUS_PRODUCT'
export const TOGGLE_SELECTED_ITEM = 'TOGGLE_SELECTED_ITEM'
export const TOGGLE_IMAGE_PRODUCT = 'TOGGLE_IMAGE_PRODUCT'
export const ON_SET_DETAIL_PRODUCT = 'ON_SET_DETAIL_PRODUCT'
export const ON_CHANGE_DETAIL_PRODUCT = 'ON_CHANGE_DETAIL_PRODUCT'
export const ON_SET_LIST_IMAGE = 'ON_SET_LIST_IMAGE'
export const RESET_FORM = 'RESET_FORM'