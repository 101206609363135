export const ON_CHECKED_ALL_TRANSACTION_UPLOAD_RESEP = 'ON_CHECKED_ALL_TRANSACTION_UPLOAD_RESEP'
export const TOGGLE_SELECTED_TRANSACTION_ITEM_UPLOAD_RESEP = 'TOGGLE_SELECTED_TRANSACTION_ITEM_UPLOAD_RESEP'
export const SET_TRANSACTION_LIST_UPLOAD_RESEP = 'SET_TRANSACTION_LIST_UPLOAD_RESEP'
export const SET_DETAIL_TRANSACTION_UPLOAD_RESEP = 'SET_DETAIL_TRANSACTION_UPLOAD_RESEP'
export const SET_FILTER_DATE_START = 'SET_FILTER_DATE_START'
export const SET_FILTER_DATE_END = 'SET_FILTER_DATE_END'
export const SET_DETAIL_APOTEK_UPLOAD_RESEP = 'SET_DETAIL_APOTEK_UPLOAD_RESEP'
export const SET_SELECTED_APOTEK_UPLOAD_RESEP = 'SET_SELECTED_APOTEK_UPLOAD_RESEP'
export const SET_DETAIL_UPLOAD_RESEP = 'SET_DETAIL_UPLOAD_RESEP'
export const SET_DETAIL_ORDER_UPLOAD_RESEP = 'SET_DETAIL_ORDER_UPLOAD_RESEP'
export const SET_DETAIL_SHIPPING_UPLOAD_RESEP = 'SET_DETAIL_SHIPPING_UPLOAD_RESEP'
export const SET_DETAIL_PATIENT_UPLOAD_RESEP = 'SET_DETAIL_PATIENT_UPLOAD_RESEP'
export const SET_DETAIL_DOCTOR_UPLOAD_RESEP = 'SET_DETAIL_DOCTOR_UPLOAD_RESEP'
export const SET_DETAIL_RECEIVER_UPLOAD_RESEP = 'SET_DETAIL_RECEIVER_UPLOAD_RESEP'
export const SET_APOTEK_INFORMATION_UPLOAD_RESEP = 'SET_APOTEK_INFORMATION_UPLOAD_RESEP'
export const SET_DOCTOR_INFORMATION_UPLOAD_RESEP = 'SET_DOCTOR_INFORMATION_UPLOAD_RESEP'
export const ON_CHECKED_ALL_ITEMS_UPLOAD_RESEP = 'ON_CHECKED_ALL_ITEMS_UPLOAD_RESEP'
export const TOGGLE_SELECTED_ITEMS_UPLOAD_RESEP = 'TOGGLE_SELECTED_ITEMS_UPLOAD_RESEP'
export const SET_ITEMS_UPLOAD_RESEP = 'SET_ITEMS_UPLOAD_RESEP'
export const SET_DIAGNOSA_UPLOAD_RESEP = 'SET_DIAGNOSA_UPLOAD_RESEP'
export const DELETE_ITEMS_UPLOAD_RESEP = 'DELETE_ITEMS_UPLOAD_RESEP'
export const RESET_DETAIL_UPLOAD_RESEP = 'RESET_DETAIL_UPLOAD_RESEP'