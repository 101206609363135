import {
    EXAMPLE_ACTION
} from '../actions/exampleAction'


const initialState = {
    example : ""
}
export default (state = initialState, action) => {
    switch (action.type) {
        case EXAMPLE_ACTION:
            return {
                ...state,
                example: action.data
            }
        default:
            return state
    }
}