import { lazy } from 'react'
import url from 'config/url';

const Dashboard = lazy(() => import('views/content/containers/Dashboard/Dashboard'))
const Login = lazy(() => import('views/content/containers/auth/login/Login'))
const ResetPassword = lazy(() => import('views/content/containers/auth/reset-password/ResetPassword'))
const AdminList = lazy(() => import('views/content/containers/admin/AdminList'))
const AdminCreate = lazy(() => import('views/content/containers/admin/AdminCreate'))
const AdminEdit = lazy(() => import('views/content/containers/admin/AdminEdit'))

// ROLE
const RoleList = lazy(() => import('views/content/containers/role/List/List'))
const RoleForm = lazy(() => import('views/content/containers/role/Form/Form'))

// HOMEPAGE
const HomepageHeaderMenuList = lazy(() => import('views/content/containers/homepage/headerMenu/List/List'))
const HomepageHeaderMenuForm = lazy(() => import('views/content/containers/homepage/headerMenu/Form/Form'))
const HomepageServiceMenuList = lazy(() => import('views/content/containers/homepage/serviceMenu/List/List'))
const HomepageServiceMenuForm = lazy(() => import('views/content/containers/homepage/serviceMenu/Form/Form'))
const HomepageDetectionMenuList = lazy(() => import('views/content/containers/homepage/detectionMenu/List/List'))
const HomepageDetectionMenuForm = lazy(() => import('views/content/containers/homepage/detectionMenu/Form/Form'))
const HomeBannerMain = lazy(() => import('views/content/containers/homepage/banner/mainBanner/List/List'))
const HomeBannerMainForm = lazy(() => import('views/content/containers/homepage/banner/mainBanner/Form/Form'))
const HomepageDownloadList = lazy(() => import('views/content/containers/homepage/download/List/List'))
const HomepageDownloadForm = lazy(() => import('views/content/containers/homepage/download/Form/Form'))
const HomepageFooter = lazy(() => import('views/content/containers/homepage/footer/List/List'))
const HomepageFooterForm = lazy(() => import('views/content/containers/homepage/footer/Form/Form'))

// HEALTHMALL
const HealthmallDashboard = lazy(() => import('views/content/containers/healthmall/dashboard/Dashboard'))
const ProductList = lazy(() => import('views/content/containers/healthmall/product/ProductList'))
const ProductCreate = lazy(() => import('views/content/containers/healthmall/product/ProductCreate'))
const ProductEdit = lazy(() => import('views/content/containers/healthmall/product/ProductEdit'))
const CategoryList = lazy(() => import('views/content/containers/healthmall/category/CategoryList'))
const CategoryCreate = lazy(() => import('views/content/containers/healthmall/category/CategoryCreate'))
const CategoryEdit = lazy(() => import('views/content/containers/healthmall/category/CategoryEdit'))
const BrandList = lazy(() => import('views/content/containers/healthmall/brand/BrandList'))
const BrandCreate = lazy(() => import('views/content/containers/healthmall/brand/BrandCreate'))
const BrandEdit = lazy(() => import('views/content/containers/healthmall/brand/BrandEdit'))
const StoreList = lazy(() => import('views/content/containers/healthmall/store/StoreList'))
const StoreCreate = lazy(() => import('views/content/containers/healthmall/store/StoreCreate'))
const StoreEdit = lazy(() => import('views/content/containers/healthmall/store/StoreEdit'))
const BannerList = lazy(() => import('views/content/containers/healthmall/banner/BannerList'))
const BannerCreate = lazy(() => import('views/content/containers/healthmall/banner/BannerCreate'))
const TransactionList = lazy(() => import('views/content/containers/healthmall/transaction/TransactionList'))
const TransactionDetail = lazy(() => import('views/content/containers/healthmall/transaction/TransactionDetail'))
const BundleList = lazy(() => import('views/content/containers/healthmall/bundle/BundleList'))
const BundleCreate = lazy(() => import('views/content/containers/healthmall/bundle/Form/Form'))

// PUBLISHING
const PublishingArticleList = lazy(() => import('views/content/containers/publishing/article/List/List'))
const PublishingArticleForm = lazy(() => import('views/content/containers/publishing/article/Form/Form'))
const PublishingBannerList = lazy(() => import('views/content/containers/publishing/banner/List/List'))
const PublishingBannerForm = lazy(() => import('views/content/containers/publishing/banner/Form/Form'))
const PublishingMainMenuList = lazy(() => import('views/content/containers/publishing/mainMenu/List/List'))
const PublishingMainMenuForm = lazy(() => import('views/content/containers/publishing/mainMenu/Form/Form'))
const PublishingListTopic = lazy(() => import('views/content/containers/publishing/topic/List/List'))
const PublishingEditTopic = lazy(() => import('views/content/containers/publishing/topic/Form/Form'))

// PRESCRIPTION
const PrescriptionTransactionList = lazy(() => import('views/content/containers/prescription/transaction/TransactionList'))
const PrescriptionTransactionDetail = lazy(() => import('views/content/containers/prescription/transaction/TransactionDetail'))
const PrescriptionDoctorInformation = lazy(() => import('views/content/containers/prescription/transaction/doctor/Information'))
const PrescriptionApotekInformation = lazy(() => import('views/content/containers/prescription/transaction/apotek/Information'))

//POIN REWARD
const PoinRewardDataTracking = lazy(() => import('views/content/containers/poinreward/dataTracking/List/List'))
const PoinRewardNewCustomer = lazy(() => import('views/content/containers/poinreward/newCustomer/List/List'))
const PoinRewardVoucherCategory = lazy(() => import('views/content/containers/poinreward/voucherCategory/List/List'))
const PoinRewardVoucherCategoryForm = lazy(() => import('views/content/containers/poinreward/voucherCategory/Form/Form'))
const PoinRewardServiceCategory = lazy(() => import('views/content/containers/poinreward/serviceCategory/List/List'))
const PoinRewardServiceCategoryForm = lazy(() => import('views/content/containers/poinreward/serviceCategory/Form/Form'))
const PoinRewardMasterVoucherKlikpoin = lazy(() => import('views/content/containers/poinreward/masterVoucher/Klikpoin/List/List'))
const PoinRewardMasterVoucherHistoryKlikpoin = lazy(() => import('views/content/containers/poinreward/masterVoucher/Klikpoin/History/List'))
const PoinRewardMasterVoucherForm = lazy(() => import('views/content/containers/poinreward/masterVoucher/Form/Form'))
const PoinRewardMasterVoucherPublic = lazy(() => import('views/content/containers/poinreward/masterVoucher/PublicVoucher/List/List'))
const PoinRewardMasterVoucherHistoryPublic = lazy(() => import('views/content/containers/poinreward/masterVoucher/PublicVoucher/History/List'))
const PoinRewardMasterVoucherExternal = lazy(() => import('views/content/containers/poinreward/masterVoucher/External/List/List'))
const PoinRewardMasterVoucherHistoryExternal = lazy(() => import('views/content/containers/poinreward/masterVoucher/External/History/List'))
const PoinRewardMasterVoucherExternalForm = lazy(() => import('views/content/containers/poinreward/masterVoucher/External/Form/Form'))

//CONTACT US
const ContactUsKorporasi = lazy(() => import('views/content/containers/contact/korporasi/List/List'))
const ContactUsKorporasiCreate = lazy(() => import('views/content/containers/contact/korporasi/Create/Create'))
const ContactUsKorporasiEdit = lazy(() => import('views/content/containers/contact/korporasi/Edit/Edit'))
const ContactUsTenagaKesehatan = lazy(() => import('views/content/containers/contact/tenagaKesehatan/List/List'))
const ContactUsTenagaKesehatanCreate = lazy(() => import('views/content/containers/contact/tenagaKesehatan/Create/Create'))
const ContactUsTenagaKesehatanEdit = lazy(() => import('views/content/containers/contact/tenagaKesehatan/Edit/Edit'))
const ContactUsFasilitasKesehatan = lazy(() => import('views/content/containers/contact/fasilitasKesehatan/List/List'))
const ContactUsFasilitasKesehatanCreate = lazy(() => import('views/content/containers/contact/fasilitasKesehatan/Create/Create'))
const ContactUsFasilitasKesehatanEdit = lazy(() => import('views/content/containers/contact/fasilitasKesehatan/Edit/Edit'))

// TEMPORARY ISOMAN
const FormResep = lazy(() => import('views/content/containers/prescription/form-resep/FormResep'))

const routes = [
  // HOMEPAGE
  { mustLogged: true, path: url.DASHBOARD.route, exact: true, name: 'Dashboard', component: Dashboard, title: 'Dashboard' },
  { mustLogged: false, path: url.LOGIN.route, exact: true, name: 'Login', component: Login, title: 'Login' },
  { mustLogged: false, path: url.ACTIVATION.route, exact: true, name: 'Activation', component: ResetPassword, title: 'Activation' },
  { mustLogged: true, path: url.ADMIN.route, exact: true, name: 'Pengaturan Admin', component: AdminList, title: 'Admin', new: true, newVal: 'TAMBAH ADMIN BARU', newLink: url.ADMIN_CREATE.route },
  { mustLogged: true, path: url.ADMIN_CREATE.route, exact: true, name: 'Informasi Admin', component: AdminCreate, title: 'Create Admin', new: false },
  { mustLogged: true, path: url.ADMIN_EDIT.route, exact: true, name: 'Informasi Admin', component: AdminEdit, title: 'Edit Admin', new: false },
  // ROLE
  { mustLogged: true, path: url.ROLE.route, exact: true, name: 'List Role', component: RoleList, title: 'List Role', new: true, newVal: 'TAMBAH ROLE BARU', newLink: url.ROLE_CREATE.route },
  { mustLogged: true, path: url.ROLE_CREATE.route, exact: true, name: 'Create Role', component: RoleForm, title: 'Create Role', new: false },
  { mustLogged: true, path: url.ROLE_EDIT.route, exact: true, name: 'Edit Role', component: RoleForm, title: 'Edit Role', new: false },
  // HOMEPAGE
  { mustLogged: true, path: url.HOMEPAGE_MENU.route, exact: true, name: 'Header Menu', component: HomepageHeaderMenuList, title: 'List Header Menu', new: true, newVal: 'TAMBAH MENU BARU', newLink: url.HOMEPAGE_MENU_CREATE.route },
  { mustLogged: true, path: url.HOMEPAGE_MENU_CREATE.route, exact: true, name: 'Create Header Menu', component: HomepageHeaderMenuForm, title: 'Create Header Menu', new: false },
  { mustLogged: true, path: url.HOMEPAGE_MENU_EDIT.route, exact: true, name: 'Edit Header Menu', component: HomepageHeaderMenuForm, title: 'Edit Header Menu', new: false },
  { mustLogged: true, path: url.HOMEPAGE_SERVICE.route, exact: true, name: 'Layanan Kesehatan', component: HomepageServiceMenuList, title: 'List Layanan Kesehatan', new: true, newVal: 'TAMBAH MENU BARU', newLink: url.HOMEPAGE_SERVICE_CREATE.route },
  { mustLogged: true, path: url.HOMEPAGE_SERVICE_CREATE.route, exact: true, name: 'Create Layanan Kesehatan', component: HomepageServiceMenuForm, title: 'Create Layanan Kesehatan', new: false },
  { mustLogged: true, path: url.HOMEPAGE_SERVICE_EDIT.route, exact: true, name: 'Edit Layanan Kesehatan', component: HomepageServiceMenuForm, title: 'Edit Layanan Kesehatan', new: false },
  { mustLogged: true, path: url.HOMEPAGE_DETECTION.route, exact: true, name: 'Cek Kesehatanmu', component: HomepageDetectionMenuList, title: 'List Cek Kesehatanmu', new: true, newVal: 'TAMBAH MENU BARU', newLink: url.HOMEPAGE_DETECTION_CREATE.route },
  { mustLogged: true, path: url.HOMEPAGE_DETECTION_CREATE.route, exact: true, name: 'Create Cek Kesehatanmu', component: HomepageDetectionMenuForm, title: 'Create Cek Kesehatanmu', new: false },
  { mustLogged: true, path: url.HOMEPAGE_DETECTION_EDIT.route, exact: true, name: 'Edit Cek Kesehatanmu', component: HomepageDetectionMenuForm, title: 'Edit Cek Kesehatanmu', new: false },
  { mustLogged: true, path: url.HOMEPAGE_BANNER_MAIN.route, exact: true, name: 'Main Banner', component: HomeBannerMain, title: 'Main Banner', new: true, newVal: 'TAMBAH BANNER BARU', newLink: url.HOMEPAGE_BANNER_MAIN_CREATE.route },
  { mustLogged: true, path: url.HOMEPAGE_BANNER_MAIN_CREATE.route, exact: true, name: 'Buat Main Banner', component: HomeBannerMainForm, title: 'Buat Main Banner', new: false },
  { mustLogged: true, path: url.HOMEPAGE_BANNER_MAIN_EDIT.route, exact: true, name: 'Edit Main Banner', component: HomeBannerMainForm, title: 'Edit Main Banner', new: false },
  { mustLogged: true, path: url.HOMEPAGE_BANNER_PROMO_PARTNER.route, exact: true, name: 'Promo & Partnertship', component: HomeBannerMain, title: 'Promo & Partnertship', new: true, newVal: 'TAMBAH BANNER BARU', newLink: url.HOMEPAGE_BANNER_PROMO_PARTNER_CREATE.route },
  { mustLogged: true, path: url.HOMEPAGE_BANNER_PROMO_PARTNER_CREATE.route, exact: true, name: 'Buat Promo & Partnertship', component: HomeBannerMainForm, title: 'Buat Promo & Partnertship', new: false },
  { mustLogged: true, path: url.HOMEPAGE_BANNER_PROMO_PARTNER_EDIT.route, exact: true, name: 'Edit Promo & Partnertship', component: HomeBannerMainForm, title: 'Edit Promo & Partnertship', new: false },
  { mustLogged: true, path: url.HOMEPAGE_DOWNLOAD.route, exact: true, name: 'Download', component: HomepageDownloadList, title: 'List Download', new: false },
  { mustLogged: true, path: url.HOMEPAGE_DOWNLOAD_CREATE.route, exact: true, name: 'Create Download', component: HomepageDownloadForm, title: 'Create Download', new: false },
  { mustLogged: true, path: url.HOMEPAGE_DOWNLOAD_EDIT.route, exact: true, name: 'Edit Download', component: HomepageDownloadForm, title: 'Edit Download', new: false },
  { mustLogged: true, path: url.HOMEPAGE_FOOTER.route, exact: true, name: 'Footer', component: HomepageFooter, title: 'Footer', new: true, newVal: 'TAMBAH FOOTER BARU', newLink: url.HOMEPAGE_FOOTER_CREATE.route },
  { mustLogged: true, path: url.HOMEPAGE_FOOTER_CREATE.route, exact: true, name: 'Add Footer', component: HomepageFooterForm, title: 'Add Footer', new: false },
  { mustLogged: true, path: url.HOMEPAGE_FOOTER_EDIT.route, exact: true, name: 'Edit Footer', component: HomepageFooterForm, title: 'Edit Footer', new: false },
  // HEALTHMALL
  { mustLogged: true, path: url.HEALTHMALL_DASHBOARD.route, exact: true, name: 'Dashboard', component: HealthmallDashboard, title: 'Dashboard' },
  { mustLogged: true, path: url.PRODUCT.route, exact: true, name: 'List Produk', component: ProductList, title: 'List Produk', new: false, newVal: 'TAMBAH PRODUK BARU', newLink: url.PRODUCT_CREATE.route },
  { mustLogged: true, path: url.PRODUCT_CREATE.route, exact: true, name: 'Draft Produk', component: ProductCreate, title: 'Draft Produk', new: false },
  { mustLogged: true, path: url.PRODUCT_EDIT.route, exact: true, name: 'Edit Produk', component: ProductEdit, title: 'Edit Produk', new: false },
  { mustLogged: true, path: url.PRODUCT_CATEGORY.route, exact: true, name: 'List Kategori', component: CategoryList, title: 'List Kategori', new: true, newVal: 'TAMBAH KATEGORI BARU', newLink: url.PRODUCT_CATEGORY_CREATE.route },
  { mustLogged: true, path: url.PRODUCT_CATEGORY_CREATE.route, exact: true, name: 'Draft Kategori', component: CategoryCreate, title: 'Draft Kategori', new: false },
  { mustLogged: true, path: url.PRODUCT_CATEGORY_EDIT.route, exact: true, name: 'Edit Kategori', component: CategoryEdit, title: 'Edit Kategori', new: false },
  { mustLogged: true, path: url.PRODUCT_BRAND.route, exact: true, name: 'List Brand', component: BrandList, title: 'List Brand', new: true, newVal: 'TAMBAH BRAND BARU', newLink: url.PRODUCT_BRAND_CREATE.route },
  { mustLogged: true, path: url.PRODUCT_BRAND_CREATE.route, exact: true, name: 'Draft Brand', component: BrandCreate, title: 'Draft Brand', new: false },
  { mustLogged: true, path: url.PRODUCT_BRAND_EDIT.route, exact: true, name: 'Edit Brand', component: BrandEdit, title: 'Edit Brand', new: false },
  { mustLogged: true, path: url.STORE.route, exact: true, name: 'List Store', component: StoreList, title: 'List Store', new: true, newVal: 'TAMBAH STORE BARU', newLink: url.STORE_CREATE.route },
  { mustLogged: true, path: url.STORE_CREATE.route, exact: true, name: 'Draft Store', component: StoreCreate, title: 'Create Store', new: false },
  { mustLogged: true, path: url.STORE_EDIT.route, exact: true, name: 'Edit Store', component: StoreEdit, title: 'Edit Store', new: false },
  { mustLogged: true, path: url.BANNER.route, exact: true, name: 'Banner', component: BannerList, title: 'List Banner', new: true, newVal: 'TAMBAH BANNER BARU', newLink: url.BANNER_CREATE.route },
  { mustLogged: true, path: url.BANNER_CREATE.route, exact: true, name: 'Informasi Banner', component: BannerCreate, title: 'Create Banner', new: false },
  { mustLogged: true, path: url.BANNER_EDIT.route, exact: true, name: 'Informasi Banner', component: BannerCreate, title: 'Edit Banner', new: false },
  { mustLogged: true, path: url.TRANSACTION.route, exact: true, name: 'List Transaksi', component: TransactionList, title: 'List Transaksi', new: false },
  { mustLogged: true, path: url.TRANSACTION_DETAIL.route, exact: true, name: 'Informasi Transaksi', component: TransactionDetail, title: 'Informasi Transaksi', new: true, newVal: 'BATAL', newLink: url.TRANSACTION.route },
  { mustLogged: true, path: url.BUNDLE.route, exact: true, name: 'List Bundling', component: BundleList, title: 'List Bundling', new: true, newVal: 'TAMBAH BUNDLING', newLink: url.BUNDLE_CREATE.route },
  { mustLogged: true, path: url.BUNDLE_CREATE.route, exact: true, name: 'Informasi Bundling', component: BundleCreate, title: 'Create Bundling', new: false },
  { mustLogged: true, path: url.BUNDLE_EDIT.route, exact: true, name: 'Informasi Bundling', component: BundleCreate, title: 'Edit Bundling', new: false },
  // PUBLISHING
  { mustLogged: true, path: url.PUBLISHING_ARTICLE.route, exact: true, name: 'List Artikel', component: PublishingArticleList, title: 'List Artikel', new: false },
  { mustLogged: true, path: url.PUBLISHING_ARTICLE_EDIT.route, exact: true, name: 'Edit Artikel', component: PublishingArticleForm, title: 'Edit Artikel', new: false },
  { mustLogged: true, path: url.PUBLISHING_BANNER.route, exact: true, name: 'List Banner', component: PublishingBannerList, title: 'List Banner', new: true, newVal: 'TAMBAH BANNER BARU', newLink: url.PUBLISHING_BANNER_CREATE.route },
  { mustLogged: true, path: url.PUBLISHING_BANNER_CREATE.route, exact: true, name: 'Create Banner', component: PublishingBannerForm, title: 'Create Banner', new: false },
  { mustLogged: true, path: url.PUBLISHING_BANNER_EDIT.route, exact: true, name: 'Edit Banner', component: PublishingBannerForm, title: 'Edit Banner', new: false },
  { mustLogged: true, path: url.PUBLISHING_MAIN_MENU.route, exact: true, name: 'List Main Menu', component: PublishingMainMenuList, title: 'List Main Menu', new: true, newVal: 'TAMBAH MENU BARU', newLink: url.PUBLISHING_MAIN_MENU_CREATE.route },
  { mustLogged: true, path: url.PUBLISHING_MAIN_MENU_CREATE.route, exact: true, name: 'Create Main Menu', component: PublishingMainMenuForm, title: 'Create Main Menu', new: false },
  { mustLogged: true, path: url.PUBLISHING_MAIN_MENU_EDIT.route, exact: true, name: 'Edit Main Menu', component: PublishingMainMenuForm, title: 'Edit Main Menu', new: false },
  { mustLogged: true, path: url.PUBLISHING_LIST_TOPIC_OBAT.route, exact: true, name: 'Topic Obat', component: PublishingListTopic, title: 'Topic Obat', new: false },
  { mustLogged: true, path: url.PUBLISHING_LIST_TOPIC_PENYAKIT.route, exact: true, name: 'Topic Penyakit', component: PublishingListTopic, title: 'Topic Penyakit', new: false },
  { mustLogged: true, path: url.PUBLISHING_EDIT_TOPIC.route, exact: true, name: 'Edit Topic', component: PublishingEditTopic, title: 'Edit Topic', new: false },
  // PRESCRIPTION
  { mustLogged: true, path: url.PRESCRIPTION_TRANSACTION_LIST.route, exact: true, name: 'Pesanan Resep', component: PrescriptionTransactionList, title: 'Pesanan Resep', new: false },
  { mustLogged: true, path: url.PRESCRIPTION_TRANSACTION_DETAIL.route, exact: true, name: 'Informasi Transaksi', component: PrescriptionTransactionDetail, title: 'Informasi Transaksi', new: false },
  { mustLogged: true, path: url.PRESCRIPTION_DOCTOR_INFORMATION.route, exact: true, name: 'Informasi Dokter', component: PrescriptionDoctorInformation, title: 'Informasi Dokter', new: false },
  { mustLogged: true, path: url.PRESCRIPTION_APOTEK_INFORMATION.route, exact: true, name: 'Informasi Apotek', component: PrescriptionApotekInformation, title: 'Informasi Apotek', new: false },
  //POIN REWARD
  { mustLogged: true, path: url.POINREWARD_DATA_TRACKING.route, exact: true, name: 'Data Tracking', component: PoinRewardDataTracking, title: 'Data Tracking', new: false },
  { mustLogged: true, path: url.POINREWARD_NEW_CUSTOMER.route, exact: true, name: 'New Customer', component: PoinRewardNewCustomer, title: 'New Customer', new: false },
  { mustLogged: true, path: url.POINREWARD_VOUCHER_CATEGORY.route, exact: true, name: 'Voucher Category', component: PoinRewardVoucherCategory, title: 'Voucher Category', new: true, newVal: 'TAMBAH VOUCHER CATEGORY', newLink: url.POINREWARD_VOUCHER_CATEGORY_CREATE.route },
  { mustLogged: true, path: url.POINREWARD_VOUCHER_CATEGORY_CREATE.route, exact: true, name: 'Voucher Category', component: PoinRewardVoucherCategoryForm, title: 'Voucher Category', new: false },
  { mustLogged: true, path: url.POINREWARD_VOUCHER_CATEGORY_EDIT.route, exact: true, name: 'Voucher Category', component: PoinRewardVoucherCategoryForm, title: 'Voucher Category', new: false },
  { mustLogged: true, path: url.POINREWARD_SERVICE_CATEGORY.route, exact: true, name: 'Service Category', component: PoinRewardServiceCategory, title: 'Service Category', new: true, newVal: 'TAMBAH SERVICE CATEGORY', newLink: url.POINREWARD_SERVICE_CATEGORY_CREATE.route },
  { mustLogged: true, path: url.POINREWARD_SERVICE_CATEGORY_CREATE.route, exact: true, name: 'Service Category', component: PoinRewardServiceCategoryForm, title: 'Service Category', new: false },
  { mustLogged: true, path: url.POINREWARD_SERVICE_CATEGORY_EDIT.route, exact: true, name: 'Voucher Category', component: PoinRewardServiceCategoryForm, title: 'Voucher Category', new: false },
  { mustLogged: true, path: url.POINREWARD_MASTER_VOUCHER_KLIKPOIN.route, exact: true, name: 'Master Voucher KlikPoin', component: PoinRewardMasterVoucherKlikpoin, title: 'Master Voucher KlikPoin', new: true, newVal: 'TAMBAH VOUCHER', newLink: url.POINREWARD_MASTER_VOUCHER_CREATE.route+'?voucherType=private' },
  { mustLogged: true, path: url.POINREWARD_MASTER_VOUCHER_HISTORY_KLIKPOIN.route, exact: true, name: 'History Voucher KlikPoin', component: PoinRewardMasterVoucherHistoryKlikpoin, title: 'History Voucher KlikPoin', new: false },
  { mustLogged: true, path: url.POINREWARD_MASTER_VOUCHER_CREATE.route, exact: true, name: 'Create Master Voucher', component: PoinRewardMasterVoucherForm, title: 'Create Master Voucher', new: false },
  { mustLogged: true, path: url.POINREWARD_MASTER_VOUCHER_EDIT.route, exact: true, name: 'Edit Master Voucher', component: PoinRewardMasterVoucherForm, title: 'Edit Master Voucher', new: false },
  { mustLogged: true, path: url.POINREWARD_MASTER_VOUCHER_PUBLIC.route, exact: true, name: 'Master Voucher Public', component: PoinRewardMasterVoucherPublic, title: 'Master Voucher Public', new: true, newVal: 'TAMBAH VOUCHER', newLink: url.POINREWARD_MASTER_VOUCHER_CREATE.route+'?voucherType=public' },
  { mustLogged: true, path: url.POINREWARD_MASTER_VOUCHER_HISTORY_PUBLIC.route, exact: true, name: 'History Voucher Public', component: PoinRewardMasterVoucherHistoryPublic, title: 'History Voucher Public', new: false },
  { mustLogged: true, path: url.POINREWARD_MASTER_VOUCHER_EXTERNAL.route, exact: true, name: 'Master Voucher External', component: PoinRewardMasterVoucherExternal, title: 'Master Voucher External', new: true, newVal: 'TAMBAH VOUCHER', newLink: url.POINREWARD_MASTER_VOUCHER_EXTERNAL_CREATE.route },
  { mustLogged: true, path: url.POINREWARD_MASTER_VOUCHER_HISTORY_EXTERNAL.route, exact: true, name: 'History Voucher External', component: PoinRewardMasterVoucherHistoryExternal, title: 'History Voucher External', new: false },
  { mustLogged: true, path: url.POINREWARD_MASTER_VOUCHER_EXTERNAL_CREATE.route, exact: true, name: 'Create Voucher External', component: PoinRewardMasterVoucherExternalForm, title: 'Create Voucher External', new: false },
  { mustLogged: true, path: url.POINREWARD_MASTER_VOUCHER_EXTERNAL_EDIT.route, exact: true, name: 'Edit Voucher External', component: PoinRewardMasterVoucherExternalForm, title: 'Edit Voucher External', new: false },
  //CONTACT US
  { mustLogged: true, path: url.CONTACT_US_KORPORASI.route, exact: true, name: 'Mitra Korporasi', component: ContactUsKorporasi, title: 'Mitra Korporasi', new: false, newVal: 'TAMBAH MITRA', newLink: url.CONTACT_US_KORPORASI_CREATE.route },
  { mustLogged: true, path: url.CONTACT_US_KORPORASI_CREATE.route, exact: true, name: 'Mitra Korporasi', component: ContactUsKorporasiCreate, title: 'Mitra Korporasi', new: false },
  { mustLogged: true, path: url.CONTACT_US_KORPORASI_EDIT.route, exact: true, name: 'Mitra Korporasi', component: ContactUsKorporasiEdit, title: 'Mitra Korporasi', new: false },
  { mustLogged: true, path: url.CONTACT_US_TENAGA_KESEHATAN.route, exact: true, name: 'Mitra Tenaga Kesehatan', component: ContactUsTenagaKesehatan, title: 'Mitra Tenaga Kesehatan', new: false, newVal: 'TAMBAH MITRA', newLink: url.CONTACT_US_TENAGA_KESEHATAN_CREATE.route },
  { mustLogged: true, path: url.CONTACT_US_TENAGA_KESEHATAN_CREATE.route, exact: true, name: 'Mitra Tenaga Kesehatan', component: ContactUsTenagaKesehatanCreate, title: 'Mitra Tenaga Kesehatan', new: false },
  { mustLogged: true, path: url.CONTACT_US_TENAGA_KESEHATAN_EDIT.route, exact: true, name: 'Mitra Tenaga Kesehatan', component: ContactUsTenagaKesehatanEdit, title: 'Mitra Tenaga Kesehatan', new: false },
  { mustLogged: true, path: url.CONTACT_US_FASILITAS_KESEHATAN.route, exact: true, name: 'Mitra Fasilitas Kesehatan', component: ContactUsFasilitasKesehatan, title: 'Mitra Fasilitas Kesehatan', new: false, newVal: 'TAMBAH MITRA', newLink: url.CONTACT_US_FASILITAS_KESEHATAN_CREATE.route },
  { mustLogged: true, path: url.CONTACT_US_FASILITAS_KESEHATAN_CREATE.route, exact: true, name: 'Mitra Fasilitas Kesehatan', component: ContactUsFasilitasKesehatanCreate, title: 'Mitra Fasilitas Kesehatan', new: false },
  { mustLogged: true, path: url.CONTACT_US_FASILITAS_KESEHATAN_EDIT.route, exact: true, name: 'Mitra Fasilitas Kesehatan', component: ContactUsFasilitasKesehatanEdit, title: 'Mitra Fasilitas Kesehatan', new: false },
  //TEMPORARY ISOMAN
  { mustLogged: false, path: url.FORM_RESEP.route, exact: true, name: 'Form Resep', component: FormResep, title: 'Form Resep', new: false },
];

export default routes;
