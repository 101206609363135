import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from './store/ContainerReducer'
import * as serviceWorker from './serviceWorker'
import axios from 'axios'

// Route
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import routes from './config/routes'
// End Route

//Error Page
import Page404 from './views/errors/Page404'
//End Error Page

// Pace
import 'pace-js';
import 'pace-js/themes/yellow/pace-theme-minimal.css';
//End Pace

// Default Styles
import './assets/scss/general.scss'
import './assets/scss/typography.scss'
// End Default Styles

axios.defaults.baseURL = process.env.REACT_APP_HEALTHMALL_APIURL
console.log("Update = 20 Nov 2020 10:00")

const loading = () => {
    return (
        <div className="lds-ring mt-2"><div></div><div></div><div></div><div></div></div>
    )
}

const ContainerLayout = React.lazy(() => import('./views/layouts/Container'))

ReactDOM.render(
    <Provider store={configureStore()}>
        <BrowserRouter>
            <Switch>
                {routes.map((route, idx) => {
                    return route.component ? (
                        <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={props => (
                                (route.mustLogged ?
                                    <Suspense fallback={loading()}>
                                        <ContainerLayout
                                            title={route.title}
                                            path={route.path}
                                            content={route.component}
                                            viewLogged={route.component}
                                            name={route.name}
                                            new={route.new}
                                            newLink={route.newLink}
                                            newVal={route.newVal}
                                            {...props} />
                                    </Suspense>
                                    :
                                    <Suspense fallback={loading()}>
                                        <route.component {...props} />
                                    </Suspense>)
                            )} />
                    ) : (null);
                })}
                <Route component={Page404} />
            </Switch>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
serviceWorker.unregister();