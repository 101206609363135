import {
    ON_CHECKED_ALL,
    ON_CHANGE_DATA_BANNER,
    ON_SET_DETAIL_BANNER,
    ON_SET_LIST_BANNER,
    RESET_BANNER_FORM,
    SET_BANNER_DATE_START,
    SET_BANNER_DATE_END,
    TOGGLE_DROPDOWN_SETTING,
    TOGGLE_ITEM_STATUS_BANNER,
    TOGGLE_SELECTED_ITEM
} from '../actions/bannerAction';


const initialState = {
    bannerList: [],
    checkAll: false,
    dataEdit: {
        name: '',
        url: '',
        description: '',
        start_date: '',
        end_date: '',
        sequence_no: '',
        status: true
    }
}
export default (state = initialState,action) => {
    const bannerList = state.bannerList;
    switch (action.type) {
        case ON_CHECKED_ALL:
            for (let i = 0; i < bannerList.length; i++) {
                bannerList[i].checked = action.check
            }
            state.checkAll = action.check;
            return {
                ...state
            }
        case TOGGLE_DROPDOWN_SETTING:
            for (let i = 0; i < bannerList.length; i++) {
                bannerList[i].dropdown = false;
            }
            bannerList[action.index].dropdown = action.checked
            return {
                ...state
            }
        case TOGGLE_ITEM_STATUS_BANNER:
            state.bannerList[action.index].status = action.checked
            return {
                ...state
            }
        case TOGGLE_SELECTED_ITEM:
            state.bannerList[action.index].checked = action.checked
            if (!action.check) {
                state.checkAll = false;
            }
            return {
                ...state
            }
        case ON_CHANGE_DATA_BANNER:
            state.dataEdit = {
                ...state.dataEdit,
                [action.key]: action.value
            }
            return {
                ...state
            }
        case SET_BANNER_DATE_START:
            state.dataEdit.start_date = action.value
            return {
                ...state
            }
        case SET_BANNER_DATE_END:
            state.dataEdit.end_date = action.value
            return {
                ...state
            }
        case ON_SET_DETAIL_BANNER:
            const newData = action.data
            newData.start_date = new Date(newData.start_date)
            newData.end_date = new Date(newData.end_date)
            state.dataEdit = newData
            return {
                ...state
            }
        case RESET_BANNER_FORM:
            state.dataEdit = {
                name: '',
                url: '',
                description: '',
                start_date: '',
                end_date: '',
                order_number: '',
                status: true
            }
            return {
                ...state
            }
        case ON_SET_LIST_BANNER:
            state.bannerList = action.data
            return {
                ...state
            }
        default:
            return state
    }
}