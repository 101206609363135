export const ON_CHECKED_ALL = 'ON_CHECKED_ALL'
export const ON_CHANGE_CATEGORY = 'ON_CHANGE_CATEGORY'
export const ON_CHANGE_INPUT = 'ON_CHANGE_INPUT'
export const ON_CHANGE_IMAGE = 'ON_CHANGE_IMAGE'
export const ON_PLUS_SUB_CATEGORY = 'ON_PLUS_SUB_CATEGORY'
export const ON_REMOVE_SUB_CATEGORY = 'ON_REMOVE_SUB_CATEGORY'
export const ON_SET_DETAIL_CATEGORY = 'ON_SET_DETAIL_CATEGORY'
export const ON_SET_LIST_CATEGORY = 'ON_SET_LIST_CATEGORY'
export const ON_SET_CHECK_CATEGORY_PRODUCT = 'ON_SET_CHECK_CATEGORY_PRODUCT'
export const TOGGLE_DROPDOWN_SETTING = 'TOGGLE_DROPDOWN_SETTING'
export const TOGGLE_ITEM_STATUS_CATEGORY = 'TOGGLE_ITEM_STATUS_CATEGORY'
export const TOGGLE_SELECTED_ITEM = 'TOGGLE_SELECTED_ITEM'
export const TOGGLE_STATUS = 'TOGGLE_STATUS'
export const RESET_FORM = 'RESET_FORM'
export const RESET_LIST_CATEGORY = 'RESET_LIST_CATEGORY'