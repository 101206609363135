import {
    ON_CHECKED_ALL_,
    ON_SET_LIST_BUNDLING,
    ON_SET_NAME_STORE,
    TOGGLE_ITEM_STATUS_BUNDLING,
    TOGGLE_SELECTED_ITEM,
    ADD_PRODUCT_GROUP,
    DELETE_PRODUCT_GROUP,
    RESET_PRODUCT_GROUPS,
    ON_DELETE_IMAGE,
    ON_UPLOADED_IMAGE,
    TOGGLE_IMAGE_BUNDLING,
    SET_BUNDLING_DATE_START,
    SET_BUNDLING_DATE_END,
    ON_SET_DETAIL_BUNDLING,
    ADD_PRODUCT_GROUP_ITEM,
    DELETE_PRODUCT_GROUP_ITEM,
    ON_CHANGE_DATA_BUNDLING,
    SET_BUNDLING_PRICE,
    RESET_FORM
} from '../actions/bundlingAction';


const initialState = {
    bundlingList: [],
    storeNamelist: [],
    checkAll: false,
    dataEdit: {
        store_id: "",
        store_name:"",
        merchant_id:"",
        merchant_name:"",
        description: "",
        name: "",
        period_end: "",
        period_start: "",
        selling_price: 0,
        special_price: 0,
        status: true,
        image: [],
        product_groups: [{
            group_item: 1,
            bundling_price: 0,
            selling_price: 0,
            products: []
        }],
    },
}
export default (state = initialState,action) => {
    const bundlingList = state.bundlingList;
    switch (action.type) {
        case ON_CHECKED_ALL_:
            for (let i = 0; i < bundlingList.length; i++) {
                bundlingList[i].checked = action.check
            }
            state.checkAll = action.check;
            return {
                ...state
            }
        case ON_DELETE_IMAGE:
            for (let i = 0; i < state.dataEdit.image.length; i++) {
                if (state.dataEdit.image[i].checked) {
                    state.dataEdit.image.splice(i, 1)
                }
            }
            return {
                ...state
            }
        case ON_UPLOADED_IMAGE:
            state.dataEdit.image.push({ id: state.dataEdit.image.length + 1, image: action.data, checked: false, type: action.type_img })
            return {
                ...state
            }
        case TOGGLE_IMAGE_BUNDLING:
            state.dataEdit.image[action.index].checked = action.check
            return {
                ...state
            }
        case ON_CHANGE_DATA_BUNDLING:
            state.dataEdit = {
                ...state.dataEdit,
                [action.key]: action.value
            }
            return {
                ...state
            }
        case RESET_FORM:
            state.dataEdit = {
                store_name:"",
                description: "",
                name: "",
                period_end: "",
                period_start: "",
                selling_price: 0,
                special_price: 0,
                store_id: "",
                merchant_id:"",
                merchant_name:"",
                status: true,
                image: [],
                product_groups: [{
                    group_item: 1,
                    bundling_price: 0,
                    selling_price: 0,
                    products: []
                }],
                
            }
            return {
                ...state
            }
        case SET_BUNDLING_DATE_START:
            state.dataEdit.period_start = action.value
            return {
                ...state
            }
        case SET_BUNDLING_DATE_END:
            state.dataEdit.period_end = action.value
            return {
                ...state
            }
        case ON_SET_DETAIL_BUNDLING:
            const newData = action.data
            newData.period_start = new Date(newData.period_start)
            newData.period_end = new Date(newData.period_end)
            state.dataEdit = newData
            return {
                ...state
            }
        case TOGGLE_ITEM_STATUS_BUNDLING:
            state.bundlingList[action.index].status = action.checked
            return {
                ...state
            }
        case ON_SET_LIST_BUNDLING:
            state.bundlingList = action.data
            return {
                ...state
            }
        case ON_SET_NAME_STORE:
            state.storeNamelist = action.data
            return {
                ...state
            }
        case TOGGLE_SELECTED_ITEM:
            state.bundlingList[action.index].checked = action.checked
            if (!action.check) {
                state.checkAll = false;
            }
            return {
                ...state
            }
        case ADD_PRODUCT_GROUP:
            state.dataEdit.product_groups.push({
                group_item: state.dataEdit.product_groups.length + 1,
                products: [],
                bundling_price: 0,
                selling_price: 0
            })
            return {
                ...state
            }
        case DELETE_PRODUCT_GROUP:
            let x = action.index
            let harga_bundling_produk = state.dataEdit.product_groups[x].bundling_price
            state.dataEdit.special_price =  state.dataEdit.special_price - harga_bundling_produk
            state.dataEdit.product_groups.splice(x, 1)
            if (state.dataEdit.product_groups.length === 0){
                state.dataEdit.selling_price = 0;
            }

            for (let i = 0; i < state.dataEdit.product_groups.length; i++) {
                if (state.dataEdit.product_groups[i] !=null) {
                    state.dataEdit.product_groups[i].group_item = i+1;
                }
            }
            return {
                ...state
            }
        case RESET_PRODUCT_GROUPS:
            const newDataEdit = {...state.dataEdit}
            newDataEdit.product_groups = [{
                group_item: 1,
                bundling_price: 0,
                selling_price: 0,
                products: []
            }]
            newDataEdit.selling_price = 0
            newDataEdit.special_price = 0
            return {
                ...state, dataEdit: newDataEdit
            }
        case ADD_PRODUCT_GROUP_ITEM:
            let idx = action.index
            let data = state.dataEdit
            data.product_groups[idx].products.push({ 
                name: action.data.name, 
                product_id: action.data.id, 
                qty: 1, 
                selling_price: action.data.selling_price, 
                special_price: action.data.special_price, 
                source_selling_price: action.data.source_price, 
                source_special_price: action.data.source_special_price, 
            })
            return {
                ...state
            }
        case DELETE_PRODUCT_GROUP_ITEM:
            let index = action.index
            let idx_item = action.index_item
            let harga_bundling_item = state.dataEdit.product_groups[index].bundling_price
            delete state.dataEdit.product_groups[index].products.splice(idx_item, 1)

            if (state.dataEdit.product_groups[index].products.length === 0){
                state.dataEdit.special_price =  state.dataEdit.special_price - harga_bundling_item
                state.dataEdit.product_groups[index].bundling_price = 0
            }
            
            return {
                ...state
            }
        case SET_BUNDLING_PRICE:
            let n = 0;
            let dataEdit = state.dataEdit;
            let produk = state.dataEdit.product_groups[action.index]
                produk.bundling_price = parseInt(action.bundling_price)

            for (let i = 0; i < dataEdit.product_groups.length; i++) {
                if (dataEdit.product_groups[i] !=null) {
                    n = n + dataEdit.product_groups[i].bundling_price
                    dataEdit.special_price = n
                }
            }
            return {
                ...state
            }
        default:
            return state
    }
}
