import {
    SET_BREADCRUMB,
    SET_SUBHEADER
} from '../actions/layoutAction'


const initialState = {
    breadcrumb: [
        {title: 'Dashboard', link: '', active: true}
    ],
    subheader : true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_BREADCRUMB:
            state.breadcrumb = action.data
            return {
                ...state
            }
        case SET_SUBHEADER:
            state.subheader = action.data
            return {
                ...state
            }
        default:
            return state
    }
}