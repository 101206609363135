import {
    ON_CHANGE_INPUT,
    ON_CHANGE_CATEGORY,
    ON_CHANGE_IMAGE,
    ON_CHECKED_ALL,
    ON_PLUS_SUB_CATEGORY,
    ON_REMOVE_SUB_CATEGORY,
    ON_SET_DETAIL_CATEGORY,
    ON_SET_LIST_CATEGORY,
    ON_SET_CHECK_CATEGORY_PRODUCT,
    TOGGLE_DROPDOWN_SETTING,
    TOGGLE_ITEM_STATUS_CATEGORY,
    TOGGLE_SELECTED_ITEM,
    TOGGLE_STATUS,
    RESET_FORM,
    RESET_LIST_CATEGORY
} from '../actions/categoryAction'


const initialState = {
    categoryList: [],
    checkAll: false,
    dataEdit: {
        id: 1,
        image: [],
        name: '',
        description: '',
        sequence_no: 0,
        status: true
    },
    dataCreate: {
        id: 1,
        image: [],
        name: '',
        description: '',
        sequence_no: 0,
        status: false
    },
    subCategory: []
}
export default (state = initialState, action) => {
    const categoryList = state.categoryList
    switch (action.type) {
        case ON_CHANGE_INPUT:
            if (action.param === 'create') {
                let valData = action.value
                if (action.key === "description") {
                    valData = action.value.replace(/<[^>]*>/g, '')
                }
                if (valData.length <= action.max) {
                    state.dataCreate = {
                        ...state.dataCreate,
                        [action.key]: action.value
                    }
                }
            } else {
                let valData = action.value
                if (action.key === "description") {
                    valData = action.value.replace(/<[^>]*>/g, '')
                }
                if (valData.length <= action.max) {
                    state.dataEdit = {
                        ...state.dataEdit,
                        [action.key]: action.value
                    }
                }
            }
            return {
                ...state
            }
        case ON_CHANGE_CATEGORY:
            if (action.param === 'parent') {
                state.categoryList[action.index].checked = action.check
                if (!action.check) {
                    for (let i = 0; i < state.categoryList[action.index].sub_categories.length; i++) {
                        state.categoryList[action.index].sub_categories[i].checked = false
                    }
                }
            } else {
                if (action.check) {
                    state.categoryList[action.index].checked = true
                }
                state.categoryList[action.index].sub_categories[action.idx].checked = action.check
            }
            return {
                ...state
            }
        case ON_CHANGE_IMAGE:
            if (action.param === 'create') {
                state.dataCreate.image = action.value
            } else {
                state.dataEdit.image = action.value
            }
            return {
                ...state
            }
        case ON_CHECKED_ALL:
            for (let i = 0; i < categoryList.length; i++) {
                categoryList[i].checked = action.check
            }
            state.checkAll = action.check
            return {
                ...state
            }
        case ON_PLUS_SUB_CATEGORY:
            if (action.idxSub === '') state.subCategory.push({ name: action.value })
            if (action.idxSub !== '') state.subCategory[action.idxSub].name = action.value
            return {
                ...state
            }
        case ON_REMOVE_SUB_CATEGORY:
            state.subCategory.splice(action.index, 1)
            return {
                ...state
            }
        case ON_SET_DETAIL_CATEGORY:
            state.dataEdit = action.data
            if (action.data.sub_categories !== undefined) {
                state.subCategory = action.data.sub_categories
            } else {
                state.subCategory = []
            }
            return {
                ...state
            }
        case TOGGLE_DROPDOWN_SETTING:
            for (let i = 0; i < categoryList.length; i++) {
                categoryList[i].dropdown = false
            }
            categoryList[action.index].dropdown = action.checked;
            return {
                ...state
            }
        case TOGGLE_ITEM_STATUS_CATEGORY:
            state.categoryList[action.index].status = action.checked;
            return {
                ...state
            }
        case TOGGLE_SELECTED_ITEM:
            state.categoryList[action.index].checked = action.checked;
            if (!action.check) {
                state.checkAll = false;
            }
            return {
                ...state
            }
        case TOGGLE_STATUS:
            if (action.param === 'create') {
                state.dataCreate.status = action.check;
            } else {
                state.dataEdit.status = action.check;
            }
            return {
                ...state
            }
        case RESET_FORM:
            state.dataCreate = {
                id: 1,
                image: [],
                name: '',
                description: '',
                sequence_no: 0,
                status: false
            }
            state.dataEdit = {
                id: 1,
                image: [],
                name: '',
                description: '',
                sequence_no: 0,
                status: true
            }
            state.subCategory = []
            return {
                ...state
            }
        case ON_SET_LIST_CATEGORY:
            state.categoryList = action.data
            return {
                ...state
            }
        case ON_SET_CHECK_CATEGORY_PRODUCT:
            for (let i = 0; i < state.categoryList.length; i++) {
                const index = action.data.findIndex(res => res.category_id === state.categoryList[i].id)
                const index2 = action.category.findIndex(res => res.category_id === state.categoryList[i].id)
                if (index >= 0) {
                    state.categoryList[i].checked = true
                }
                if (index2 >= 0) {
                    state.categoryList[i].checked = true
                }
                for (let j = 0; j < state.categoryList[i].sub_categories.length; j++) {
                    const indexSub = action.data.findIndex(res => res.sub_category_id === state.categoryList[i].sub_categories[j].id)
                    if (indexSub >= 0) {
                        state.categoryList[i].sub_categories[j].checked = true
                    } else {
                        state.categoryList[i].sub_categories[j].checked = false
                    }
                }
            }
            return {
                ...state
            }
        case RESET_LIST_CATEGORY:
            state.categoryList = []
            return {
                ...state
            }
        default:
            return state
    }
}