const url = {
  DASHBOARD: {route: "/", menu_code: ""},
  LOGIN: {route: "/login", menu_code: ""},
  ACTIVATION: {route: "/activation/:verification_id", menu_code: ""},
  ADMIN: {route: "/admin", menu_code: ""},
  ADMIN_CREATE: {route: "/admin/create", menu_code: ""},
  ADMIN_EDIT: {route: "/admin/edit", menu_code: ""},
  // ROLE
  ROLE: {route: "/role", menu_code: ""},
  ROLE_CREATE: {route: "/role/create", menu_code: ""},
  ROLE_EDIT: {route: "/role/edit/:id", menu_code: ""},
  // HOMEPAGE
  HOMEPAGE: { route: "/homepage", menu_code: "HPM1001" },
  HOMEPAGE_MENU: { route: "/homepage/header-menu", menu_code: "HPM1001" },
  HOMEPAGE_MENU_CREATE: { route: "/homepage/header-menu/create", menu_code: "HPM1001" },
  HOMEPAGE_MENU_EDIT: { route: "/homepage/header-menu/edit/:id", menu_code: "HPM1001" },
  HOMEPAGE_SERVICE: { route: "/homepage/service", menu_code: "HPM1002" },
  HOMEPAGE_SERVICE_CREATE: { route: "/homepage/service/create", menu_code: "HPM1002" },
  HOMEPAGE_SERVICE_EDIT: { route: "/homepage/service/edit/:id", menu_code: "HPM1002" },
  HOMEPAGE_DETECTION: { route: "/homepage/detection", menu_code: "HPM1003" },
  HOMEPAGE_DETECTION_CREATE: { route: "/homepage/detection/create", menu_code: "HPM1003" },
  HOMEPAGE_DETECTION_EDIT: { route: "/homepage/detection/edit/:id", menu_code: "HPM1003" },
  HOMEPAGE_BANNER_MAIN: { route: "/homepage/banner/main", menu_code: "HPM1004" },
  HOMEPAGE_BANNER_MAIN_CREATE: { route: "/homepage/banner/main/create", menu_code: "HPM1004" },
  HOMEPAGE_BANNER_MAIN_EDIT: { route: "/homepage/banner/main/edit/:id", menu_code: "HPM1004" },
  HOMEPAGE_BANNER_PROMO_PARTNER: { route: "/homepage/banner/promo-partner", menu_code: "HPM1005" },
  HOMEPAGE_BANNER_PROMO_PARTNER_CREATE: { route: "/homepage/banner/promo-partner/create", menu_code: "HPM1005" },
  HOMEPAGE_BANNER_PROMO_PARTNER_EDIT: { route: "/homepage/banner/promo-partner/edit/:id", menu_code: "HPM1005" },
  HOMEPAGE_BANNER_ADVERTISING: { route: "/homepage/banner/advertising", menu_code: "HPM1006" },
  HOMEPAGE_BANNER_ADVERTISING_CREATE: { route: "/homepage/banner/advertising/create", menu_code: "HPM1006" },
  HOMEPAGE_BANNER_ADVERTISING_EDIT: { route: "/homepage/banner/advertising/edit/:id", menu_code: "HPM1006" },
  HOMEPAGE_DOWNLOAD: { route: "/homepage/download", menu_code: "HPM1007" },
  HOMEPAGE_DOWNLOAD_CREATE: { route: "/homepage/download/create", menu_code: "HPM1007" },
  HOMEPAGE_DOWNLOAD_EDIT: { route: "/homepage/download/edit/:id", menu_code: "HPM1007" },
  HOMEPAGE_FOOTER: { route: "/homepage/footer", menu_code: "HPM1008" },
  HOMEPAGE_FOOTER_CREATE: { route: "/homepage/footer/create", menu_code: "HPM1008" },
  HOMEPAGE_FOOTER_EDIT: { route: "/homepage/footer/edit/:id", menu_code: "HPM1008" },
  // HEALTH MALL
  HEALTHMALL: {route: "/healthmall", menu_code: "BLS1001"},
  HEALTHMALL_DASHBOARD: {route: "/healthmall", menu_code: "BLS1001"},
  PRODUCT: {route: "/healthmall/product/all", menu_code: "BLS1101"},
  PRODUCT_CREATE: {route: "/healthmall/product/create", menu_code: "BLS1101"},
  PRODUCT_EDIT: {route: "/healthmall/product/edit/:id", menu_code: "BLS1101"},
  PRODUCT_CATEGORY: {route: "/healthmall/product/category", menu_code: "BLS1102"},
  PRODUCT_CATEGORY_CREATE: {route: "/healthmall/product/category/create", menu_code: "BLS1102"},
  PRODUCT_CATEGORY_EDIT: {route: "/healthmall/product/category/edit/:id", menu_code: "BLS1102"},
  PRODUCT_BRAND: {route: "/healthmall/product/brand", menu_code: "BLS1103"},
  PRODUCT_BRAND_CREATE: {route: "/healthmall/product/brand/create", menu_code: "BLS1103"},
  PRODUCT_BRAND_EDIT: {route: "/healthmall/product/brand/edit", menu_code: "BLS1103"},
  STORE: {route: "/healthmall/store", menu_code: "BLS1002"},
  STORE_CREATE: {route: "/healthmall/store/create", menu_code: "BLS1002"},
  STORE_EDIT: {route: "/healthmall/store/edit", menu_code: "BLS1002"},
  BANNER: {route: "/healthmall/banner", menu_code: "BLS1003"},
  BANNER_CREATE: {route: "/healthmall/banner/create", menu_code: "BLS1003"},
  BANNER_EDIT: {route: "/healthmall/banner/edit/:id", menu_code: "BLS1003"},
  TRANSACTION: {route: "/healthmall/transaction", menu_code: "BLS1004"},
  TRANSACTION_DETAIL: {route: "/healthmall/transaction/detail", menu_code: "BLS1004"},
  BUNDLE: {route: "/healthmall/product/bundle/", menu_code: "BLS1005"},
  BUNDLE_CREATE: {route: "/healthmall/product/bundle/create", menu_code: "BLS1006"},
  BUNDLE_EDIT: {route: "/healthmall/product/bundle/edit/:id", menu_code: "BLS1007"},
  // PUBLISHING
  PUBLISHING: {route: "/publishing", menu_code: "PBS1001"},
  PUBLISHING_ARTICLE: {route: "/publishing/article", menu_code: "PBS1001"},
  PUBLISHING_ARTICLE_EDIT: {route: "/publishing/article/edit/:id", menu_code: "PBS1001"},
  PUBLISHING_BANNER: {route: "/publishing/banner", menu_code: "PBS1002"},
  PUBLISHING_BANNER_CREATE: {route: "/publishing/banner/create", menu_code: "PBS1002"},
  PUBLISHING_BANNER_EDIT: {route: "/publishing/banner/edit/:id", menu_code: "PBS1002"},
  PUBLISHING_MAIN_MENU: {route: "/publishing/main-menu", menu_code: ""},
  PUBLISHING_MAIN_MENU_CREATE: {route: "/publishing/main-menu/create", menu_code: ""},
  PUBLISHING_MAIN_MENU_EDIT: {route: "/publishing/main-menu/edit/:id", menu_code: ""},
  PUBLISHING_LIST_TOPIC_OBAT: {route: "/publishing/topic/obat", menu_code: ""},
  PUBLISHING_LIST_TOPIC_PENYAKIT: {route: "/publishing/topic/penyakit", menu_code: ""},
  PUBLISHING_EDIT_TOPIC: {route: "/publishing/topic/edit/:type/:slug", menu_code: ""},

  // PRESCRIPTION
  PRESCRIPTION: {route: "/prescription", menu_code: ""},
  PRESCRIPTION_ORDER_LIST: {route: "/prescription/order", menu_code: ""},
  PRESCRIPTION_TRANSACTION_LIST: {route: "/prescription/transaction", menu_code: ""},
  PRESCRIPTION_TRANSACTION_DETAIL: {route: "/prescription/transaction/:id", menu_code: ""},
  PRESCRIPTION_DOCTOR_INFORMATION: {route: "/prescription/transaction/:id/doctor", menu_code: ""},
  PRESCRIPTION_APOTEK_INFORMATION: {route: "/prescription/transaction/:id/apotek", menu_code: ""},
  
  // POINT REWARD
  POINREWARD: {route: "/reward-points", menu_code: ""},
  POINREWARD_DATA_TRACKING: {route: "/reward-points/dashboard/data-tracking", menu_code: ""},
  POINREWARD_NEW_CUSTOMER: {route: "/reward-points/dashboard/new-customer", menu_code: ""},

  POINREWARD_VOUCHER_CATEGORY: {route: "/reward-points/master/voucher-category", menu_code: ""},
  POINREWARD_VOUCHER_CATEGORY_CREATE: {route: "/reward-points/master/voucher-category/create", menu_code: ""},
  POINREWARD_VOUCHER_CATEGORY_EDIT: {route: "/reward-points/master/voucher-category/edit/:id", menu_code: ""},
  POINREWARD_SERVICE_CATEGORY: {route: "/reward-points/master/service-category", menu_code: ""},
  POINREWARD_SERVICE_CATEGORY_CREATE: {route: "/reward-points/master/service-category/create", menu_code: ""},
  POINREWARD_SERVICE_CATEGORY_EDIT: {route: "/reward-points/master/service-category/edit/:id", menu_code: ""},
  POINREWARD_MASTER_VOUCHER_KLIKPOIN: {route: "/reward-points/master/voucher/klikpoin", menu_code: ""},
  POINREWARD_MASTER_VOUCHER_HISTORY_KLIKPOIN: {route: "/reward-points/master/voucher/klikpoin/history/:id", menu_code: ""},
  POINREWARD_MASTER_VOUCHER_CREATE: {route: "/reward-points/master/voucher/create", menu_code: ""},
  POINREWARD_MASTER_VOUCHER_EDIT: {route: "/reward-points/master/voucher/edit/:id", menu_code: ""},
  POINREWARD_MASTER_VOUCHER_PUBLIC: {route: "/reward-points/master/voucher/public", menu_code: ""},
  POINREWARD_MASTER_VOUCHER_HISTORY_PUBLIC: {route: "/reward-points/master/voucher/public/history/:id", menu_code: ""},
  POINREWARD_MASTER_VOUCHER_EXTERNAL: {route: "/reward-points/master/voucher/external", menu_code: ""},
  POINREWARD_MASTER_VOUCHER_HISTORY_EXTERNAL: {route: "/reward-points/master/voucher/external/history/:id", menu_code: ""},
  POINREWARD_MASTER_VOUCHER_EXTERNAL_CREATE: {route: "/reward-points/master/voucher/external/create", menu_code: ""},
  POINREWARD_MASTER_VOUCHER_EXTERNAL_EDIT: {route: "/reward-points/master/voucher/external/edit/:id", menu_code: ""},

  //CONTACT US
  CONTACT_US: {route: "/contact", menu_code: ""},
  CONTACT_US_KORPORASI: {route: "/contact/korporasi", menu_code: ""},
  CONTACT_US_KORPORASI_CREATE: {route: "/contact/korporasi/create", menu_code: ""},
  CONTACT_US_KORPORASI_EDIT: {route: "/contact/korporasi/edit/:id", menu_code: ""},
  CONTACT_US_TENAGA_KESEHATAN: {route: "/contact/tenaga-kesehatan", menu_code: ""},
  CONTACT_US_TENAGA_KESEHATAN_CREATE: {route: "/contact/tenaga-kesehatan/create", menu_code: ""},
  CONTACT_US_TENAGA_KESEHATAN_EDIT: {route: "/contact/tenaga-kesehatan/edit/:id", menu_code: ""},
  CONTACT_US_FASILITAS_KESEHATAN: {route: "/contact/fasilitas-kesehatan", menu_code: ""},
  CONTACT_US_FASILITAS_KESEHATAN_CREATE: {route: "/contact/fasilitas-kesehatan/create", menu_code: ""},
  CONTACT_US_FASILITAS_KESEHATAN_EDIT: {route: "/contact/fasilitas-kesehatan/edit/:id", menu_code: ""},

  // TEMPORARY ISOMAN
  FORM_RESEP: { route: '/form-resep', menu_code: ''}
};

export const generateDynamicUrl = (path, params = {}) => {
  let result = path;
  if (params) {
      // eslint-disable-next-line array-callback-return
      Object.keys(params).map(key => {
          const regex = new RegExp(`/:${key}`, "gi");
          result = result.replace(regex, `/${params[key]}`)
      })
  }
  return result;
}

export default url;