import {
    ON_CHECKED_ALL,
    TOGGLE_DROPDOWN_SETTING,
    TOGGLE_SELECTED_ITEM,
    ON_SET_DETAIL_ADMIN
} from '../actions/adminAction'


const initialState = {
    adminList: [
        { id: 1, name: 'Apraticia Liberto', email: 'apraticialiberto@gmail.com', role: 'Super Admin', checked: true, dropdown: false },
        { id: 2, name: 'Apraticia Liberto', email: 'apraticialiberto@gmail.com', role: 'Super Admin', checked: true, dropdown: false },
        { id: 3, name: 'Apraticia Liberto', email: 'apraticialiberto@gmail.com', role: 'Super Admin', checked: false, dropdown: false },
        { id: 4, name: 'Apraticia Liberto', email: 'apraticialiberto@gmail.com', role: 'Super Admin', checked: false, dropdown: false },
        { id: 5, name: 'Apraticia Liberto', email: 'apraticialiberto@gmail.com', role: 'Super Admin', checked: false, dropdown: false },
    ],
    checkAll: false,
    adminDetail: {
        name: '',
        email: '',
        role: ''
    }
}
export default (state = initialState, action) => {
    const adminList = state.adminList
    switch (action.type) {
        case ON_CHECKED_ALL:
            for (let i = 0; i < adminList.length; i++) {
                adminList[i].checked = action.check
            }
            state.checkAll = action.check
            return {
                ...state
            }
        case TOGGLE_DROPDOWN_SETTING:
            for (let i = 0; i < adminList.length; i++) {
                adminList[i].dropdown = false
            }
            adminList[action.index].dropdown = action.checked
            return {
                ...state
            }
        case TOGGLE_SELECTED_ITEM:
            state.adminList[action.index].checked = action.check
            if (!action.check) {
                state.checkAll = false
            }
            return {
                ...state
            }
        case ON_SET_DETAIL_ADMIN:
            state.adminDetail = action.data
            return {
                ...state
            }
        default:
            return state
    }
}