import {
    SET_FILTER_DATE_FROM,
    SET_FILTER_DATE_TO,
} from '../actions/newCustomerAction'


const initialState = {
    filter: {
        date_from: '',
        date_to: ''
    },
}
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTER_DATE_FROM:
            state.filter.date_from = action.value
            return {
                ...state
            }
        case SET_FILTER_DATE_TO:
            state.filter.date_to = action.value
            return {
                ...state
            }
        default:
            return state
    }
}