export const ON_CHECKED_ALL_ = 'ON_CHECKED_ALL_'
export const ON_SET_LIST_BUNDLING = 'ON_SET_LIST_BUNDLING'
export const ON_SET_NAME_STORE = 'ON_SET_NAME_STORE'
export const TOGGLE_ITEM_STATUS_BUNDLING = 'TOGGLE_ITEM_STATUS_BUNDLING'
export const TOGGLE_SELECTED_ITEM = 'TOGGLE_SELECTED_ITEM'
export const ADD_PRODUCT_GROUP = 'ADD_PRODUCT_GROUP'
export const DELETE_PRODUCT_GROUP = 'DELETE_PRODUCT_GROUP'
export const RESET_PRODUCT_GROUPS = 'RESET_PRODUCT_GROUPS'
export const ON_DELETE_IMAGE = 'ON_DELETE_IMAGE'
export const ON_UPLOADED_IMAGE = 'ON_UPLOADED_IMAGE'
export const TOGGLE_IMAGE_BUNDLING = 'TOGGLE_IMAGE_BUNDLING'
export const SET_BUNDLING_DATE_START = 'SET_BUNDLING_DATE_START'
export const SET_BUNDLING_DATE_END = 'SET_BUNDLING_DATE_END'
export const ON_SET_DETAIL_BUNDLING = 'ON_SET_DETAIL_BUNDLING'
export const ADD_PRODUCT_GROUP_ITEM = 'ADD_PRODUCT_GROUP_ITEM'
export const DELETE_PRODUCT_GROUP_ITEM = 'DELETE_PRODUCT_GROUP_ITEM'
export const SET_BUNDLING_PRICE = 'SET_BUNDLING_PRICE'
export const ON_CHANGE_DATA_BUNDLING = 'ON_CHANGE_DATA_BUNDLING'
export const RESET_FORM = 'RESET_FORM'
