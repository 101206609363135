import {
    ON_CHANGE_DATA_BRAND,
    ON_CHECKED_ALL,
    ON_SET_DETAIL_BRAND,
    ON_SET_LIST_BRAND,
    TOGGLE_DROPDOWN_SETTING,
    TOGGLE_ITEM_STATUS_BRAND,
    TOGGLE_SELECTED_ITEM,
    TOGGLE_STATUS
} from '../actions/brandAction'


const initialState = {
    brandList: [],
    checkAll: false,
    dataEdit: {
        image: '',
        name: '',
        sequence_no: 0,
        // source_brand_id: null,
        // code: null,
        // principal: null
    },
    dataCreate: {
        id: 1,
        image: '',
        name: '',
        sequence_no: 0,
    }
}
export default (state = initialState, action) => {
    const brandList = state.brandList
    switch (action.type) {
        case ON_CHANGE_DATA_BRAND:
            if (action.param === 'create') {
                state.dataCreate = {
                    ...state.dataCreate,
                    [action.key]: action.value
                }
            } else {
                state.dataEdit = {
                    ...state.dataEdit,
                    [action.key]: action.value
                }
            }
            return {
                ...state
            }
        case ON_CHECKED_ALL:
            for (let i = 0; i < brandList.length; i++) {
                brandList[i].checked = action.check
            }
            state.checkAll = action.check
            return {
                ...state
            }
        case ON_SET_DETAIL_BRAND:
            state.dataEdit = action.data
            return {
                ...state
            }
        case TOGGLE_DROPDOWN_SETTING:
            for (let i = 0; i < brandList.length; i++) {
                brandList[i].dropdown = false
            }
            brandList[action.index].dropdown = action.checked
            return {
                ...state
            }
        case TOGGLE_ITEM_STATUS_BRAND:
            state.brandList[action.index].is_display = action.checked
            return {
                ...state
            }
        case TOGGLE_SELECTED_ITEM:
            state.brandList[action.index].checked = action.check
            if (!action.check) {
                state.checkAll = false
            }
            return {
                ...state
            }
        case TOGGLE_STATUS:
            if (action.param === 'create') {
                state.dataCreate.status = action.check
            } else {
                state.dataEdit.status = action.check
            }
            return {
                ...state
            }
        case ON_SET_LIST_BRAND:
            state.brandList = action.data
            return {
                ...state
            }
        default:
            return state
    }
}