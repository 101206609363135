import {
    SET_DATA_FOOTER,
    SET_DATA_FOOTER_DETAIL,
    SET_DATA_FOOTER_DETAIL_SOCMED,
    ADD_SUB_MENU,
    ADD_SOCMED,
    DELETE_SUB_MENU,
    DELETE_SOCMED,
    GET_DATA_FOOTER,
    RESET_FORM,

} from '../actions/footerMenuAction'


const initialState = {
    dataFooterMenu: {
        name: '',
        detail: [
            {
                name: '',
                deeplink: '',
                sub_title: '',
                description: '',
                social_media: [],
                title_footer: ''
            },
        ],
        footer_type: '',
        is_published: false
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DATA_FOOTER:
            state.dataFooterMenu = action.value
            return {
                ...state
            }
        case SET_DATA_FOOTER:
            state.dataFooterMenu = {
                ...state.dataFooterMenu,
                [action.key]: action.value
            }
            return {
                ...state
            }
        case SET_DATA_FOOTER_DETAIL:
            state.dataFooterMenu.detail[action.idx][action.key] = action.value
            return {
                ...state
            }
        case SET_DATA_FOOTER_DETAIL_SOCMED:
            let fieldName = action.name
            let idx_socmed = action.idx_socmed
            state.dataFooterMenu.detail[action.idx].social_media[idx_socmed][fieldName] = action.value
            return {
                ...state
            }
        case ADD_SUB_MENU:
            state.dataFooterMenu.detail.push({
                name: '',
                deeplink: '',
                sub_title: '',
                description: '',
                social_media: [
                    {
                        icon: '',
                        name: '',
                        deeplink: '',
                    },
                ],
                title_footer: ''
            })
            return {
                ...state
            }
        case ADD_SOCMED:
            state.dataFooterMenu.detail[action.idx].social_media.push({
                icon: '',
                name: '',
                deeplink: '',
            })
            return {
                ...state
            }
        case DELETE_SUB_MENU:
            let x = action.index
            state.dataFooterMenu.detail.splice(x, 1)
            return {
                ...state
            }
        case DELETE_SOCMED:
            let idx = action.index;
            let index_socmed = action.idx_socmed
            state.dataFooterMenu.detail[idx].social_media.splice(index_socmed, 1)
            return {
                ...state
            }
        case RESET_FORM:
            state.dataFooterMenu = {
                name: '',
                detail: [
                    {
                        name: '',
                        deeplink: '',
                        sub_title: '',
                        description: '',
                        social_media: [],
                        title_footer: ''
                    },
                ],
                footer_type: '',
                is_published: false
            }
            return {
                ...state
            }
        default:
            return state
    }
}