import {
    ON_CHECKED_ALL,
    TOGGLE_SELECTED_ITEM,
    SET_DETAIL_TRANSACTION,
    SET_FILTER_DATE_START,
    SET_FILTER_DATE_END,
    ON_SET_TRACKING_ORDER
} from '../actions/transactionAction'


const initialState = {
    transactionList: [
        {
            invoice: 'ID13902934',
            date: new Date(),
            receiverName: 'Audrey H',
            shippingAddress: "Gedung Panin Bank, Jalan Gunung Balong Kavling VII Nomor 16 I, Lebak Bulus, Jakarta Selatan",
            shippingMethod: "JNE-INSTAN",
            products: [
                'CDR Effervescent 20 Tablet', 'Blackmores Odourless Fish Oil 1000 (200)', 'Actived Batuk Merah',
                'OBH Komix', 'OBH herbal', 'CDR Effervescent 20 Tablet'
            ],
            total: 139000,
            paymentMethod: 'OVO',
            orderStatus: 'Selesai',
            deliveryStatus: 'With Delivery Courier',
            checked: false
        },
        {
            invoice: 'ID13902934',
            date: new Date(),
            receiverName: 'Audrey H',
            shippingAddress: "Gedung Panin Bank, Jalan Gunung Balong Kavling VII Nomor 16 I, Lebak Bulus, Jakarta Selatan",
            shippingMethod: "JNE-INSTAN",
            products: [
                'CDR Effervescent 20 Tablet', 'Blackmores Odourless Fish Oil 1000 (200)', 'Actived Batuk Merah',
                'OBH Komix', 'OBH herbal', 'CDR Effervescent 20 Tablet'
            ],
            total: 139000,
            paymentMethod: 'OVO',
            orderStatus: 'Selesai',
            deliveryStatus: 'With Delivery Courier',
            checked: false
        },
        {
            invoice: 'ID13902934',
            date: new Date(),
            receiverName: 'Audrey H',
            shippingAddress: "Gedung Panin Bank, Jalan Gunung Balong Kavling VII Nomor 16 I, Lebak Bulus, Jakarta Selatan",
            shippingMethod: "JNE-INSTAN",
            products: [
                'CDR Effervescent 20 Tablet', 'Blackmores Odourless Fish Oil 1000 (200)', 'Actived Batuk Merah',
                'OBH Komix', 'OBH herbal', 'CDR Effervescent 20 Tablet'
            ],
            total: 139000,
            paymentMethod: 'OVO',
            orderStatus: 'Selesai',
            deliveryStatus: 'With Delivery Courier',
            checked: false
        },
        {
            invoice: 'ID13902934',
            date: new Date(),
            receiverName: 'Audrey H',
            shippingAddress: "Gedung Panin Bank, Jalan Gunung Balong Kavling VII Nomor 16 I, Lebak Bulus, Jakarta Selatan",
            shippingMethod: "JNE-INSTAN",
            products: [
                'CDR Effervescent 20 Tablet', 'Blackmores Odourless Fish Oil 1000 (200)', 'Actived Batuk Merah',
                'OBH Komix', 'OBH herbal', 'CDR Effervescent 20 Tablet'
            ],
            total: 139000,
            paymentMethod: 'OVO',
            orderStatus: 'Selesai',
            deliveryStatus: 'With Delivery Courier',
            checked: false
        },
        {
            invoice: 'ID13902934',
            date: new Date(),
            receiverName: 'Audrey H',
            shippingAddress: "Gedung Panin Bank, Jalan Gunung Balong Kavling VII Nomor 16 I, Lebak Bulus, Jakarta Selatan",
            shippingMethod: "JNE-INSTAN",
            products: [
                'CDR Effervescent 20 Tablet', 'Blackmores Odourless Fish Oil 1000 (200)', 'Actived Batuk Merah',
                'OBH Komix', 'OBH herbal', 'CDR Effervescent 20 Tablet'
            ],
            total: 139000,
            paymentMethod: 'OVO',
            orderStatus: 'Selesai',
            deliveryStatus: 'With Delivery Courier',
            checked: false
        }
    ],
    checkAll: false,
    detail: {
        invoice: 'ID13902934',
        created_at: '05 Nov 2020, 15.45',
        date: new Date(),
        receiverName: 'Audrey H',
        shippingAddress: "Gedung Panin Bank, Jalan Gunung Balong Kavling VII Nomor 16 I, Lebak Bulus, Jakarta Selatan",
        shippingMethod: "JNE - INSTAN",
        products: [
            { name: 'CDR Effervescent 20 Tablet', price: 120000, qty: 1 },
            { name: 'Blackmores Odourless Fish Oil 1000 (200)', price: 120000, qty: 1 },
            { name: 'Actived Batuk Merah', price: 120000, qty: 1 },
            { name: 'OBH Komix', price: 120000, qty: 1 },
            { name: 'OBH herbal', price: 120000, qty: 1 }
        ],
        subTotal: 600000,
        shippingPrice: 18000,
        total: 618000,
        paymentMethod: 'OVO',
        orderStatus: 'Selesai',
        deliveryStatus: 'With Delivery Courier',
    },
    isDetail: false,
    filter: {
        date_start: new Date(),
        date_end: new Date()
    },
    trackOrder:{
        tracking_number: "",
        shipping_date: "",
        shipping_name: "",
        shipping_code: "",
        store_name: "",
        tracking: []
    }
}
export default (state = initialState, action) => {
    const transactionList = state.transactionList
    switch (action.type) {
        case ON_CHECKED_ALL:
            for (let i = 0; i < transactionList.length; i++) {
                transactionList[i].checked = action.check
            }
            state.checkAll = action.check
            return {
                ...state
            }
        case TOGGLE_SELECTED_ITEM:
            state.transactionList[action.index].checked = action.check
            if (!action.check) {
                state.checkAll = false
            }
            return {
                ...state
            }
        case SET_DETAIL_TRANSACTION:
            state.detail = action.data
            state.isDetail = true
            return {
                ...state
            }
        case SET_FILTER_DATE_START:
            state.filter.date_start = action.value
            return {
                ...state
            }
        case SET_FILTER_DATE_END:
            state.filter.date_end = action.value
            return {
                ...state
            }
        case ON_SET_TRACKING_ORDER:
            state.trackOrder = action.data
            return {
                ...state
            }
        default:
            return state
    }
}